import React, { useState } from "react";

function ButtonOrderPaket(prop: any) {
  const [nameClient, setNameClient] = useState("");
  const [showForm, setShowForm] = useState("hidden");
  const [hideButton, setHideButton] = useState("block");

  const handleOrderForm = () => {
    setShowForm("block");
    setHideButton("hidden");
  };

  const handleChangeFormContact = (e: any) => {
    setNameClient(e.target.value);
  };

  const HandleClikOrderPaket = (e: any) => {
    e.preventDefault();
    window.open(
      `https://api.whatsapp.com/send/?phone=%206285735106764&text=Haloo%20kak..%20Saya%20${nameClient}%20Mau%20order%20layanan%20Teman%20ScaleUp%20${prop.paket}`,
      "_blank"
    ) ||
      window.location.replace(
        `https://api.whatsapp.com/send/?phone=%206285735106764&text=Haloo%20kak..%20Saya%20${nameClient}%20Mau%20order%20layanan%20Teman%20ScaleUp%20${prop.paket}`
      );
  };
  return (
    <>
      <button
        className={`rounded-xl bg-white shadow font-bold text-black py-3 px-3 ${hideButton}`}
        onClick={handleOrderForm}
      >
        Order {prop.paket}
      </button>

      <form
        onSubmit={(e: any) => HandleClikOrderPaket(e)}
        className={`grid grid-cols-1 md:grid-cols-[24%_1%_75%] items-center py-3 px-1 ${showForm}`}
      >
        <label htmlFor="nameServiceOrder" className="font-bold ">
          Nama <span className="md:hidden inline mx-2">:</span>
        </label>{" "}
        <span className="hidden md:inline">:</span>{" "}
        <input
          type="text"
          placeholder="Masukkan Nama"
          id="nameServiceOrder"
          name="nameServiceOrder"
          value={nameClient}
          required
          className="my-2 mx-2 border-2 text-black"
          onChange={(e: any) => {
            handleChangeFormContact(e);
          }}
        />
        <input
          type="submit"
          className="md:col-span-3 self-items-center w-full rounded-3xl cursor-pointer bg-white text-black font-bold whitespace-pre-wrap text-sm py-2 px-3 my-3 md:text-lg"
          value={"Pesan Melalui Whatsapp"}
        />
      </form>
    </>
  );
}

export default ButtonOrderPaket;
